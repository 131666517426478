<template>
  <BlankLayout>
    <UplifyLogo />
    <div class="container">
      <img :src="require('assets/img/referral-check.gif')">
      <h1>{{ locale.referralCheck.title }}</h1>
      <p v-html="locale.referralCheck.text" />
      <ElButton
        type="primary"
        @click="$router.push('/')"
      >
        {{ locale.referralCheck.btnText }}
      </ElButton>
    </div>
  </BlankLayout>
</template>

<script>
import BlankLayout from 'components/layouts/BlankLayout.vue'
import UplifyLogo from 'components/UplifyLogo'
import ElButton from 'element/ElButton'

export default {
  components: {
    BlankLayout,
    UplifyLogo,
    ElButton,
  },

  computed: {
    locale: ({ $rootLocale }) => ({
      referralCheck: $rootLocale('views.streamer.referralCheck'),
    }),
  },

  methods: {
    onClick () {
      window.location = '/'
    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .uplify-logo {
      position: absolute;
      left: 24px;
      top: 36px;
    }

    .el-button {
      width: 240px;
    }
  }
  .container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 92px;

    img {
      width: 480px;
      height: auto;
      margin-bottom: 32px;
    }

    h1 {
      font-size: 32px;
      line-height: 1;
      font-weight: 700;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 1.1;
      color: #666666;

      ::v-deep a {
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
</style>
